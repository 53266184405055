import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';

const MobileMenu = (props) => {
    const data = useStaticQuery(graphql`
        query MainMobileMenuQuery {
            allMainMenuJson {
                edges {
                    node {
                        name
                        url
                        weight
                    }
                }
            }
        }
    `);
    return (
        <div
            id='main-menu-mobile'
            className={`main-menu-mobile ${props.active ? 'open' : ''}`}
        >
            <ul>
                {data.allMainMenuJson.edges.map(({ node }) => (
                    <li key={node.name}>
                        <Link to={node.url} activeClassName='active'>
                            {node.name}
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default MobileMenu;
